.btn {
  @apply py-2 px-4 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75;
}
.wrapper{
    margin:50px;
}

.toggleCheckbox {
  &:checked {
    @apply right-0 border-green-400;
    right: 0;
    border-color: #68d391;
  }
  &:checked + label {
    @apply bg-green-400;
    background-color: #68d391;
  }
}
