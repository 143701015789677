.btn {
  @apply py-2 px-4 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75;
}

.input[type="text"] {
  @apply appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.input[type="tel"] {
  @apply appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.input[type="email"] {
  @apply appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.textArea {
  @apply appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.errorMessage {
  padding: 20px;
  border: 2px solid #d84429;
  line-height: 1rem;
  margin-bottom: 30px;
  color: #d84429;
}
.toggleCheckbox {
  &:checked {
    @apply right-0 border-green-400;
    right: 0;
    border-color: #68d391;
  }
  &:checked + label {
    @apply bg-green-400;
    background-color: #68d391;
  }
}
.input {
  @apply appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.radio {
  input[type="radio"] + label span {
    transition: background 0.2s, transform 0.2s;
  }

  input[type="radio"] + label span:hover,
  input[type="radio"] + label:hover span {
    transform: scale(1.2);
  }

  input[type="radio"]:checked + label span {
    background-color: #3490dc; //bg-blue
    box-shadow: 0px 0px 0px 2px white inset;
  }

  input[type="radio"]:checked + label {
    color: #3490dc; //text-blue
  }
}
