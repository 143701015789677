.icon {
  width: 100%;
  text-align: center;
  svg {
    stroke: gray;
    fill: gray;
    width: 50px;
    height: 50px;
  }
}
