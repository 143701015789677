.btn {
  @apply py-2 px-4 bg-indigo-500 text-white font-semibold rounded-lg shadow-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-400 focus:ring-opacity-75;
}

.input {
  @apply appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight;
}

.errorMessage {
  padding: 20px;
  border: 2px solid #d84429;
  line-height: 1rem;
  margin-bottom: 30px;
  color: #d84429;
}
